import React from 'react';
import {Element} from "react-scroll";
import styled from 'styled-components';


import {Layout} from "@components";
import {SECTIONS} from "@constants";



const Index = () => {
    return (
        <div id="theme">
             <Layout>
                <HomeSections/>
             </Layout>
        </div>
    );
}


function HomeSections(){
    return (
        <React.Fragment>
        {
            SECTIONS.map((section,index) => (
                <Element key={index} className={`section section-${section.id} ${section.dark ? 'dark' : ''}`} id={section.anchor}>
                    {
                        !section.hideTitle &&
                        <SectionTitle dark={section.dark} className="section-title">
                            <h2>{section.title || section.label}</h2>
                            {
                                section.subtitle &&
                                <SectionSubtitle dark={section.dark} className="section-subtitle">{section.subtitle}</SectionSubtitle>
                            }
                        </SectionTitle>
                    }
                    <SectionBody full={section.full} className="section-body">
                        <section.component/>
                    </SectionBody>
                </Element>
            ))
        }
        </React.Fragment>
    )
}

const SectionTitle = styled.header`
    padding: 64px 0;
    text-align: center;
    h2{
        font-size: 2.6rem;
        color: ${({dark}) => dark ? '#e4e4e4' : 'var(--text-color)'}
    }

    @media(max-width: 767px){
        padding: 32px;
    }
`

const SectionSubtitle = styled.p`
    line-height: 1.2;
    color: ${({dark}) =>  dark ? '#a9a8a8' : '#6c757d'};
    margin: 16px;
`
const SectionBody = styled.main`
    flex-grow: 1;
    max-width: ${({full}) => full ? `unset` : '1200px'};
    margin: ${({full}) => full ? `0` : '0 auto'};
    width: 100%;
`

export default Index;


